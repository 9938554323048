export const isLocalStorageSupported = () => {
  try {
    const testKey = '__c_tmp_item__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};
