import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestMediaPermissionsDiv } from './RequestMediaPermissionsStyle';
import { SimpleText } from '../../AppStyle';
import desktop1 from '../../Assets/Permissions/cookies-desktop-1.png';
import desktop2 from '../../Assets/Permissions/cookies-desktop-2.png';

const RequestCookiesPermissions: React.FC = () => {
  const { t: translate } = useTranslation('media-permission');

  return (
    <RequestMediaPermissionsDiv>
      <SimpleText>
        <strong>{translate('REQUEST_COOKIES_PERMISSIONS.BLOCKED_COOKIES')}</strong>
      </SimpleText>

      <>
        <SimpleText>{translate('REQUEST_COOKIES_PERMISSIONS.TUTORIAL.STEP1.TEXT')}</SimpleText>
        <img src={desktop1} alt={translate('REQUEST_COOKIES_PERMISSIONS.IMG.ALT')} width="250" />

        <SimpleText className="margintopo">
          {translate('REQUEST_COOKIES_PERMISSIONS.TUTORIAL.STEP2.TEXT')}
          <strong>{translate('REQUEST_COOKIES_PERMISSIONS.TUTORIAL.STEP2.STRONG')}</strong>,
          {translate('REQUEST_COOKIES_PERMISSIONS.TUTORIAL.STEP3.TEXT')}
          <strong>{translate('REQUEST_COOKIES_PERMISSIONS.TUTORIAL.STEP3.STRONG')}</strong>
          {translate('REQUEST_COOKIES_PERMISSIONS.TUTORIAL.STEP4.TEXT')}
          <strong>{translate('REQUEST_COOKIES_PERMISSIONS.TUTORIAL.STEP4.STRONG')}</strong>
        </SimpleText>
        <img src={desktop2} alt={translate('REQUEST_COOKIES_PERMISSIONS.IMG.ALT')} width="580" />
      </>
    </RequestMediaPermissionsDiv>
  );
};

export default RequestCookiesPermissions;
