import styled, { keyframes } from 'styled-components';

const ChangeDescriptionKeyframe = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SubTitleText = styled.p`
  font-size: 2em;
  font-family: var(--font-family);
  font-weight: 400;
  margin: 0.5em 0;
`;

export const SimpleText = styled.p`
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  font-family: var(--font-family);
`;

export const LargeText = styled.p`
  font-weight: bold;
  font-size: 1.3em;
  font-family: var(--font-family);
  letter-spacing: 0;
  color: #707070;
  margin: 5px 0;
`;

export const Button = styled.button`
  color: #ffffff;
  background-color: #02022d;
  border: none;
  font-family: var(--font-family);
  font-size: 0.9em;
  letter-spacing: 0;
  padding: 12px 30px;
  border-radius: 200em;
  height: 3em;
  &:hover:enabled {
    background-color: #36404e;
  }
  &:disabled {
    opacity: 0.4;
  }
`;

export const DefaultButton = styled.button<{ $positionType?: string }>`
  padding: 12px 24px;
  margin-top: 20px;
  border: none;
  background: var(--primary);
  border-radius: var(--border-radius);
  font-family: var(--font-family);
  font-size: 0.9em;
  letter-spacing: 0;
  color: #ffffff;
  box-sizing: border-box;
  position: ${props => (props.$positionType ? props.$positionType : 'static')};
  ${props => (props.$positionType === 'absolute' ? 'width: auto !important;' : '')}
  &:hover {
    opacity: 0.8;
  }
  &.hide {
    display: none;
  }
  &.clear {
    padding: 0.4em 1.5em;
    background: transparent !important;
    color: var(--primary);
    &:hover {
      text-decoration: underline;
    }
  }
  &.outline {
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
    background: transparent !important;
    border: 1px solid var(--primary);
    color: var(--primary);
  }
`;

export const Text = styled.p<{ fontSize?: string }>`
  font-family: var(--font-family);
  font-size: ${props => (props.fontSize ? props.fontSize : '1em')};
  letter-spacing: 0;
  color: #707070;
`;

export const OutlineButton = styled.button`
  border: 1px solid #707070;
  background-color: transparent;
  padding: 11px 24px;
  border-radius: 200em;
  font: 0.9em Raleway;
  letter-spacing: 0;
  color: #707070;
  &:hover:enabled {
    border-color: #55af8c;
    color: #55af8c;
  }
  &:disabled {
    opacity: 0.4;
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 0;
  margin-bottom: 1em;
  border-bottom: 1px solid #575757;
`;

export const AppComponent = styled.div`
  @keyframes toast {
    0% {
      top: -200px;
    }
    100% {
      top: 0;
    }
  }

  @keyframes toastHide {
    0% {
      top: 0;
    }
    100% {
      top: -200px;
    }
  }
`;

export const Input = styled.input`
  border: 1px solid #575757;
  font-family: var(--font-family);
  font-weight: 400;
  width: 100%;
  padding: 0.5em 1em;
  margin-top: 10px;
  font-size: 1em;
  box-sizing: border-box;
  margin-right: 0.5em;
  &:last-child {
    margin-right: 0;
  }
  &:disabled {
    background: #f2f2f3;
  }
  &.error {
    border: 1px solid red;
  }
`;

export const TextArea = styled.textarea`
  border: 1px solid #575757;
  font-family: var(--font-family);
  font-weight: 400;
  width: 100%;
  padding: 0.5em 1em;
  margin-top: 10px;
  font-size: 1em;
  box-sizing: border-box;
`;

export const Select = styled.select`
  width: 100%;
  font-size: 1em;
  padding: 0.5em 1em;
  border: 1px solid #575757;
  font-weight: 400;
  font-family: var(--font-family);
`;

export const Option = styled.option`
  padding: 0.5em;
  font-size: 1em;
  font-weight: 400;
`;

export const TitleLinesBefore = styled.div`
  display: flex;
  margin-left: 0 !important;
  align-items: center;
  p {
    font-family: var(--font-family);
    font-size: 1.5em;
    letter-spacing: 0;
    color: #7d7d7d;
  }
  * {
    order: 3;
    width: -webkit-fill-available;
  }
  &:before {
    order: 1;
    content: ' ';
    width: 3vw;
    display: inline-block;
    height: 2px;
    background: #157dd1;
    margin-right: 15px;
  }
  &:after {
    order: 0;
    content: ' ';
    width: 0.5vw;
    display: inline-block;
    height: 2px;
    background: #157dd1;
    margin-right: 5px;
  }
`;

export const DefaultInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
  &.inline {
    flex-direction: row;
    align-items: center;
  }
  & > label {
    font-size: var(--font-size);
    font-weight: normal;
    font-family: var(--font-family);
    letter-spacing: 0;
    color: var(--text-color);
    padding-left: 5px;
    &.error {
      color: var(--red);
    }
  }
  input,
  textarea,
  select {
    font-size: 1em;
    font-family: var(--font-family);
    letter-spacing: 0;
    color: var(--text-color);
    padding: 5px 16px 5px 16px;
    border: 0px;
    background-color: #e9e9e9;
    border-radius: var(--border-radius);
    height: 2.5em;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
    &:disabled {
      border: none;
      background-color: var(--background-white-gradient) !important;
    }
    &[type='checkbox'].error {
      outline: 1.5px solid var(--red);
      outline-offset: 1px;
    }
    &.error {
      border: 1px solid var(--red);
    }
    &::placeholder {
      color: #70707070;
    }
    &::-webkit-input-placeholder {
      color: #70707070;
    }
    &::-ms-input-placeholder {
      color: #70707070;
    }
    &::-moz-placeholder {
      color: #70707070;
    }
    &:-moz-placeholder {
      color: #70707070;
    }
  }
  textarea {
    resize: none;
    height: 100px;
    padding: 10px 16px 10px 16px !important;
  }
  button.plus-button {
    width: 5em;
    border: 1px solid #707070;
    color: #707070;
    border-radius: 100em;
    background: transparent;
    font-size: 1em;
    font-family: var(--font-family);
    letter-spacing: 0;
    padding: 5px 12px 6px 12px;
    opacity: 0.3;
    display: flex;
    i {
      margin: auto;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  button.delete {
    &:not(:first-child) {
      margin-left: 5px;
    }
    background: transparent;
    color: #707070;
    font-size: 1em;
    font-family: var(--font-family);
    letter-spacing: 0;
    border: none;
    opacity: 0.5;
    &:hover {
      color: #e74c3c;
    }
  }
  .text {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--grey);
    &.error {
      color: var(--red);
      font-weight: 600;
    }
  }
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
  input[type='checkbox'] {
    height: auto;
    margin: 10px;
  }
  &.small {
    input,
    select {
      width: 15em;
    }
  }
  &.tiny {
    input,
    select {
      width: 7em;
    }
  }
`;

export const DefaultInputSecondary = styled(DefaultInput)`
  input,
  textarea,
  select {
    background: #f9fafa;
    border: 1px solid #02022dcc;
    border-radius: 8px;
    padding: 12px 16px;
    height: unset;
  }
  & > label {
    font-weight: 600 !important;
  }
`;

export const Card = styled.div`
  margin-top: 2em;
  display: flex;
  width: -webkit-fill-available;
  height: fit-content;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 15px;
  padding: 1.5em;
  max-width: 750px;
  animation: ${ChangeDescriptionKeyframe} 0.5s ease-out;
  position: relative;
  overflow: hidden;

  &.no-padding-left-right {
    padding: 0.5em 0;
  }

  &:before {
    content: '';
    width: 100%;
    height: 10px;
    background: var(--dark-blue);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &:last-child {
    margin-bottom: 2em;
  }

  .buttons-box.align-right {
    width: fit-content;
    margin: 1em 0 0.5em auto;
  }

  .buttons-box.align-center {
    width: fit-content;
    margin: 1em auto 0.5em auto;
  }
`;
