import styled, { keyframes } from 'styled-components';

const NewLoadingAnimation = keyframes`
  0% {
    transform: translateX(40px);
  }
  
  50% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(40px);
  }
`;

export const NewLoadingContent = styled.div`
  margin: 40px;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 10px;
  background: var(--primary);
  border-radius: 5px;
  animation: ${NewLoadingAnimation} 2s ease-in-out infinite;
  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    animation: ${NewLoadingAnimation} 2s ease-in-out infinite;
    height: 10px;
    border-radius: 5px;
  }
  &:before {
    top: -20px;
    left: 10px;
    width: 40px;
    background: var(--secondary);
  }
  &:after {
    bottom: -20px;
    width: 35px;
    background: var(--secondary);
  }
`;
