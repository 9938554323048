import styled from 'styled-components';

export const ErrorGetMediasContent = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  background: var(--background-white-gradient);
  transition: all 0.2s ease-in;
  div {
    background-color: #fff;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    width: fit-content;
    max-width: 28em;
    height: fit-content;
    border-radius: 10px;
    margin: 5em auto auto auto;
    padding: 2em;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-family: var(--font-family);
      white-space: break-spaces;
      font-weight: 500;
      font-size: 1.1em;
      color: var(--grey);
      text-align: center;
      span {
        font-size: 1.3em;
      }
    }
    svg {
      font-size: 4em;
      opacity: 0.6;
      color: var(--grey);
    }
    @media (max-width: 800px) {
      margin-top: 0;
    }
  }
`;
