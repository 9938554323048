import { createSlice, Dispatch } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    enableLoading: state => ({ ...state, loading: true }),
    disableLoading: state => ({ ...state, loading: false }),
  },
});

const { actions, reducer } = loadingSlice;

export const { enableLoading, disableLoading } = actions;

export const actionLoading = (active: boolean) => (dispatch: Dispatch) => {
  if (active) {
    dispatch(enableLoading());
  } else {
    dispatch(disableLoading());
  }
};

export default reducer;
