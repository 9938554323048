import React, { Component } from 'react';
import { DefaultButton, LargeText } from '../../AppStyle';
import ErrorHandlerUtil, { ErrorData } from '../../Util/ErrorHandler.util';
import { isLocalStorageSupported } from '../../Util/LocalStorage';
import RequestCookiesPermissions from '../RequestMediaPermissions/RequestCookiesPermissions';
import { ErrorBoundaryComponent } from './ErrorBoundaryStyle';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string | null;
  redirect: boolean;
  redirectUrl: string;
  redirectText: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: null,
      redirect: false,
      redirectUrl: 'https://nuvidio.com/',
      redirectText: 'Conheça a NuVidio',
    };
  }

  public componentDidCatch(error: any) {
    let { redirect, redirectUrl, redirectText } = this.state;
    const errorData: ErrorData = ErrorHandlerUtil.returnErrorData(error);
    redirect = errorData.redirect || false;
    redirectText = errorData.redirectText || 'Conheça o NuVidio';
    redirectUrl = errorData.redirectUrl || 'https://nuvidio.com.br/';

    this.setState({
      redirect,
      redirectText,
      redirectUrl,
      hasError: true,
      errorMessage: errorData.message,
    });
  }

  public static reloadWindow(): void {
    window.location.reload();
  }

  public static redirectLink(link: string): void {
    window.location.href = link;
  }

  public render(): JSX.Element {
    const { hasError, errorMessage, redirect, redirectText, redirectUrl } = this.state;
    const { children } = this.props;

    if (!isLocalStorageSupported()) {
      return <RequestCookiesPermissions />;
    }

    if (hasError) {
      return (
        <ErrorBoundaryComponent>
          <div>
            <LargeText>{errorMessage || ''}</LargeText>
            <div>
              <DefaultButton
                onClick={
                  redirect
                    ? () => ErrorBoundary.redirectLink(redirectUrl)
                    : ErrorBoundary.reloadWindow
                }
              >
                {redirect ? redirectText : 'Atualizar Página'}
              </DefaultButton>
            </div>
          </div>
        </ErrorBoundaryComponent>
      );
    }

    return children as JSX.Element;
  }
}

export default ErrorBoundary;
