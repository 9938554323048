import { TraceModel } from '../types';

export class Constants {
  // Urls de conexão com a api
  public static readonly CONNECTION_STRING: string = process.env.REACT_APP_CONNECTION_STRING || '';

  public static readonly DAILY_URL: string = process.env.REACT_APP_DAILY_URL || '';

  // URL Site
  public static readonly SITE_URL: string = 'https://nuvidio.com.br';

  public static readonly STANDARD_ERROR_MESSAGE: string =
    'Ops! Parece que ocorreu um erro ao conectar com nosso servidor.' +
    ' Verifique sua conexão e tente novamente.';

  // Identificadores utilizado no local storage
  public static readonly LOCAL_STORAGE_USER_DEFAULT_CONFIG_MIC: string = 'u_d_c_m_nuvidio';

  public static readonly LOCAL_STORAGE_USER_DEFAULT_CONFIG_CAM: string = 'u_d_c_c_nuvidio';

  public static readonly LOCAL_STORAGE_USER_MEDIA_INPUT: string = 'u_m_i_nuvidio';

  public static readonly LOCAL_STORAGE_USER_MEDIA_OUTPUT: string = 'u_m_o_nuvidio';

  public static readonly LOCAL_STORAGE_USER_MEDIA_CAM: string = 'u_m_c_nuvidio';

  public static readonly LOCAL_STORAGE_DEPARTMENT_INFO: string = 'd_i_nuvidio';

  public static readonly COOKIE_QUEUE_CUSTOMER_ID: string = 'c_ci_nuvidio';

  // SOUND
  public static readonly NEW_PARTICIPANT_JOIN_CALL_SOUND: string =
    'https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/NotificationsSounds/join.wav';

  // Trace Events
  public static readonly NETWORK_UNSTABLE_TRACE: TraceModel = {
    statusName: 'unstable_network',
    user: 'customer',
    detail: 'User has a unstable network',
  };

  public static readonly SHARED_SCREEN_TRACE: TraceModel = {
    statusName: 'screen_shared',
    user: 'customer',
    detail: 'User has shared the screen',
  };

  public static readonly STOP_SHARE_SCREEN_TRACE: TraceModel = {
    statusName: 'stop_screen_share',
    user: 'customer',
    detail: 'User has stopped shared the screen',
  };

  public static readonly ENABLED_MIC_TRACE: TraceModel = {
    statusName: 'enabled_mic',
    user: 'customer',
    detail: 'User has enabled microfone',
  };

  public static readonly DISABLED_MIC_TRACE: TraceModel = {
    statusName: 'disabled_mic',
    user: 'customer',
    detail: 'User has disabled microfone',
  };

  public static readonly ENABLED_CAMERA_TRACE: TraceModel = {
    statusName: 'enabled_camera',
    user: 'customer',
    detail: 'User has enabled camera',
  };

  public static readonly DISABLED_CAMERA_TRACE: TraceModel = {
    statusName: 'disabled_camera',
    user: 'customer',
    detail: 'User has disabled camera',
  };

  public static readonly CUSTOMER_SHARE_FILE_TRACE: TraceModel = {
    statusName: 'shared_file',
    user: 'customer',
    detail: 'User has shared file',
  };

  public static readonly MEDIA_PERMISSIONS_DENIED: TraceModel = {
    statusName: 'media_permissions_denied',
    user: 'customer',
    detail: 'User media permissions denied',
  };
}
export default Constants;
