import styled from 'styled-components';
import { DefaultButton } from '../../AppStyle';

export const ErrorBoundaryComponent = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: fit-content;
    height: fit-content;
    margin: auto;
    max-width: 40vw;
    p,
    h1 {
      margin: 1em auto;
      width: fit-content;
      text-align: center;
    }
    ${DefaultButton} {
      margin: 1em;
      font-size: 18;
    }
    @media (max-width: 700px) {
      max-width: 90vw;
    }
    @media (max-width: 1200px) {
      max-width: 60vw;
    }
  }
`;
