import styled from 'styled-components';
import { DefaultButton } from '../../AppStyle';
import { isMobile } from '../../Util/Mobile.util';

const textChatMarginRight = '12px';

export const VideoRoomWrapper = styled.div<{ background?: string; mobileHeight: number }>`
  width: 100%;
  ${props => (props.mobileHeight && isMobile ? `height: ${props.mobileHeight}px` : '')};
  display: flex;
  position: relative;
  background: ${props => props.background || 'var(--video-room-bg)'};

  .text-chat {
    display: flex;
    transition: transform 0.3s ease;

    ${isMobile
      ? `
      height: 99% !important;
      margin: 0% auto 1%;
      width: 94vw;
      border-radius: 16px;
    `
      : `
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 8px;
    `}
  }

  .call-off {
    position: fixed;
    ${isMobile
      ? `
      top: 20%; left: 2vw;
      transform: translateY(80vh);
    `
      : `
      header.header {
        position: relative;
      }
      top: 3%;
      height: 87% !important;
      left: calc(100% - 300px - ${textChatMarginRight}) !important;
      transform: translateX(calc(100vw + 100%)) !important;
      box-sizing: border-box;
    `};

    @media screen and (max-width: 720px) {
      ${isMobile
        ? ''
        : `
        top: 0;
        height: 100% !important;
        margin-top: 0;
        width: 100vw !important;
      `}
    }
  }

  .call-on {
    position: relative;
    ${isMobile ? '' : `margin-right: ${textChatMarginRight};`}
    transform: translateX(0);
    transform: translateY(0);
  }

  @media screen and (max-width: 720px) {
    .text-chat {
      ${isMobile
        ? `
        width: 94vw;
        overflow: hidden;
      `
        : ''}
    }
    .call-on {
      ${isMobile
        ? ''
        : `
        position: fixed;
        width: 100%;
      `}
    }
  }
`;

export const VideoRoomContent = styled.div<{ $textChatIsOpen: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.is-keyboard-open {
    grid-template-rows: ${props =>
      props.$textChatIsOpen ? 'minmax(80x, 2fr) 3fr !important' : '55px auto 65px'};
  }

  @media screen and (max-width: 720px) {
    ${isMobile ? 'display: grid; grid-gap: 8px;' : ''}

    :not(&.is-keyboard-open) {
      grid-template-rows: ${props =>
        props.$textChatIsOpen && isMobile ? '1fr 4fr 65px' : '48px auto 65px'};
    }
  }

  #desktop-room-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const FinishButton = styled(DefaultButton)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1em;
  z-index: 200;
  background: var(--red);
  opacity: 0.7;
  ${isMobile &&
  `
    transform: scale(.8);
    margin: 5px 0;
  `};
  @media (max-width: 700px) {
    top: 0;
    bottom: auto;
    right: auto;
    left: 0;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-white-gradient);
`;
