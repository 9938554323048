import styled from 'styled-components';
import { SimpleText, DefaultButton } from '../../AppStyle';
import { isMobile } from '../../Util/Mobile.util';

export const RequestMediaPermissionsDiv = styled.div`
  ${isMobile ? 'background-color: var(--background-white-gradient);' : ''}
  padding: 0.5em;
  margin: auto auto;
  max-width: 100%;
  height: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${SimpleText} {
    font-size: 1.2em;
    text-align: center;
    align-self: center;
    flex: 0;
    padding: 10px;
    &.margin-topo {
      padding-top: 15px;
    }
  }

  img {
    margin-top: 10px auto;
  }

  ${DefaultButton} {
    margin-top: 30px;
  }

  @media (max-width: 800px) {
    width: 80%;
    img {
      max-width: 70%;
    }
    ${SimpleText} {
      font-size: 0.9em;
      &.margin-topo {
        padding-top: 0;
      }
    }
  }
  @media (max-width: 1200px) {
    width: 20em;
    ${SimpleText} {
      font-size: 1em;
    }
  }
`;

export const ContainerCloseButton = styled.div`
  margin-top: 1em;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10%;
  padding-left: 10%;
`;
