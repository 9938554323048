import { createContext } from 'react';
import { DepartmentInterface } from '../../Types/Department';
import { ForwardingRouterInterface } from '../../Types/Router';

export interface IClientAppContext {
  department?: DepartmentInterface;
  setDepartment: (department: DepartmentInterface) => void;
  router?: ForwardingRouterInterface;
  setRouter: (router: ForwardingRouterInterface) => void;
}

export const ClientAppContext = createContext<IClientAppContext>(null!);
