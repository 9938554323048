class ErrorHandlerUtil {
  public static readonly STANDARD_ERROR_MESSAGE: string =
    'Ops! Parece que ocorreu um erro ao conectar com nosso servidor. ' +
    'Verifique sua conexão e tente novamente.';

  public static readonly CONNECTION_TWILIO_ERROR_MESSAGE: string =
    'Ops! Não conseguimos estabelecer a conexão de vídeo. Por favor tente novamente. ' +
    'Caso o problema continue entre em contato conosco através do email: "hello@nuvidio.com.br".';

  public static returnErrorData(error: any): ErrorData {
    const { data } = error.response || { data: null };

    if (data && data.message) {
      return { message: data.message, token: data.token || undefined, ...data };
    }

    return { message: ErrorHandlerUtil.STANDARD_ERROR_MESSAGE, ...data };
  }
}
export default ErrorHandlerUtil;

export interface ErrorData {
  message: string;
  token?: string;
  code?: number;
  redirect?: boolean;
  redirectUrl?: string;
  redirectText?: string;
}
