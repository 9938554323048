import { configureStore } from '@reduxjs/toolkit';
import alertReducer from './Slices/AlertSlice';
import loadingReducer from './Slices/LoadingSlice';
import transferCallDataReducer from './Slices/TransferCallDataSlice';

const store = configureStore({
  reducer: {
    alert: alertReducer,
    loading: loadingReducer,
    transferCallData: transferCallDataReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
