import React, { useState } from 'react';
import { ClientAppContext } from '../Contexts/ClientApp/ClientAppContext';
import useLocalStorage from '../Hooks/useLocalStorage';
import { DepartmentInterface } from '../Types/Department';
import { ForwardingRouterInterface } from '../Types/Router';
import Constants from '../Util/Constants.util';

interface ClientAppProviderProps {
  children: React.ReactNode;
}

const ClientAppProvider: React.FC<ClientAppProviderProps> = ({ children }) => {
  const [router, setRouter] = useState<ForwardingRouterInterface | undefined>();
  const [department, setDepartment] = useLocalStorage<DepartmentInterface | undefined>(
    Constants.LOCAL_STORAGE_DEPARTMENT_INFO,
    undefined
  );

  return (
    <ClientAppContext.Provider
      value={{
        department,
        setDepartment,
        router,
        setRouter,
      }}
    >
      {children}
    </ClientAppContext.Provider>
  );
};

export default ClientAppProvider;
