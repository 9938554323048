import styled from 'styled-components';

export const RatingContent = styled.div`
  margin: auto;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;

  > div.rating-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 650px;
    max-width: 100%;

    .logo img {
      max-width: 200px;
      margin: 50px auto 30px auto;
    }

    > img.thanks-for-rating {
      width: 20em;
      height: 13em;
    }

    > span.rating-title {
      color: var(--grey);
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      margin: auto 20px 20px 20px;
    }

    > span.rating-legend {
      color: var(--grey);
      font-weight: normal;
      font-size: 1.1em;
      text-align: center;
      line-height: 1.3em;
      margin-top: 5px;
      text-align: center;
    }

    > div.rating-options {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      margin: 2.5em auto;

      > div.rating-card {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 4em;

        > span.star {
          transition: 0.3s;
          -webkit-filter: grayscale(100%) brightness(200%);
          filter: grayscale(100%) brightness(200%);
          -moz-filter: grayscale(100%) brightness(200%);
          filter: gray;
          margin-bottom: 10px;

          &.enable {
            /*-webkit-filter: hue-rotate(190deg);*/
            -webkit-filter: none;
            filter: none;
            -moz-filter: none;
          }
        }

        > span {
          font-size: 0.8em;
          color: var(--grey);
          text-align: center;
        }
      }
    }

    button {
      border: none;
      border-radius: 6px;
      width: auto;
      background: var(--primary);
      font-family: var(--font-family);
      padding: 12px 40px;
      color: white;
      font-size: 1em;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }

      @media (max-width: 800px) {
        font-size: 0.8em;
        padding: 16px 50px;
      }
    }
  }

  .rating-emojis {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: space-between;
    align-items: center;

    .active-rating {
      opacity: 1;
    }

    .non-active-rating {
      opacity: 0.5;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.7;

        > p {
          color: black;
        }
      }

      svg {
        width: 2.8em;
        height: 2.8em;
        margin-bottom: 0.6em;
      }

      > p {
        font-size: 0.9em;
        color: var(--grey);
        text-align: center;
        margin: 0;
      }
    }
  }

  .nps-numbers {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 85vw;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      background-color: #fbe9e9;
      border-radius: 0.4em;
      cursor: pointer;
      width: 2.6em;
      height: 2.6em;

      span {
        font-size: 1.3em;
        font-weight: bold;
        color: #ea5455;
      }
      &:hover {
        background-color: rgba(234, 84, 85, 0.4);
      }
      &.active-rating {
        background-color: rgba(234, 84, 85, 1) !important;
        span {
          color: white;
        }
      }
      &:nth-child(7),
      &:nth-child(8) {
        background-color: rgba(255, 159, 67, 0.12);
        span {
          color: #ff9f43;
        }
        &:hover {
          background-color: rgba(255, 159, 67, 0.4);
        }
        &.active-rating {
          background-color: rgba(255, 159, 67, 1) !important;
          span {
            color: white;
          }
        }
      }

      &:nth-child(9),
      &:nth-child(10) {
        background-color: rgba(40, 199, 111, 0.12);
        span {
          color: #28c76f;
        }
        &:hover {
          background-color: rgba(40, 199, 111, 0.4);
        }
        &.active-rating {
          background-color: rgba(40, 199, 111, 1) !important;
          span {
            color: white;
          }
        }
      }
    }
  }

  .nps-emojis {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    .active-rating {
      opacity: 1;
    }
    .non-active-rating {
      opacity: 0.8;
    }
    .non-active-rating.has-some-selected {
      opacity: 0.4;
    }
    .non-active-rating:hover {
      opacity: 1;
    }

    .nps-emojis-section {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      gap: 10px;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 5px;
        bottom: -5px;
        z-index: 111;
        background-color: purple;
        border-radius: 100px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:hover {
          cursor: pointer;
        }

        svg {
          width: 2.2em;
          height: 2.2em;
          margin-bottom: 0.1em;
        }

        span {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 0.9em;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          font-weight: bold;
          color: white;
          margin-top: 0.3em;
          z-index: 20;

          &:after {
            content: '';
            position: absolute;
            width: 1px;
            height: 7px;
            bottom: -7px;
            z-index: 1;
          }
        }
      }

      padding-bottom: 7px;

      &.nps-emojis-bad {
        &:after {
          background-color: #ea5455;
        }

        div {
          span {
            background-color: #ea5455;
          }

          span:after {
            background-color: #ea5455;
          }
        }
      }

      &.nps-emojis-warning {
        &:after {
          background-color: #fddd5a;
        }

        div {
          span {
            background-color: #fddd5a;
          }

          span:after {
            background-color: #fddd5a;
          }
        }
      }

      &.nps-emojis-good {
        &:after {
          background-color: #90d890;
        }

        div {
          span {
            background-color: #90d890;
          }

          span:after {
            background-color: #90d890;
          }
        }
      }
    }
  }
`;
