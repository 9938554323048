import { createRoot } from 'react-dom/client';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Replay, BrowserTracing } from '@sentry/react';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import App from './App';
import './index.css';
import './tailwind.css';
import Constants from './Util/Constants.util';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = Constants.CONNECTION_STRING;
axios.defaults.timeout = 30000;

if (
  process.env.REACT_APP_ENVIRONMENT &&
  ['production'].includes(process.env.REACT_APP_ENVIRONMENT)
) {
  Sentry.init({
    dsn: 'https://71fe199104304d2ebe796109a964a75c@o1209174.ingest.sentry.io/6350561',
    integrations: [
      new BrowserTracing(),
      new Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.05,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng',
    },
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: {
      pt: ['pt-BR'],
      en: ['en-US'],
      es: ['es'],
      default: ['pt-BR'],
    },
  });

const root = createRoot(document.getElementById('root')!);
root.render(<App />);
