import React from 'react';
import { useDispatch } from 'react-redux';
import { DefaultButton } from '../../AppStyle';
import { IAlertOptions, IButtonOptions } from './AlertController';
import {
  AlertBackGround,
  AlertButtonsGroup,
  AlertComponent,
  AlertIcon,
  AlertMessage,
  AlertSubTitle,
  AlertTitle,
} from './AlertStyle';
import { disableAlert } from '../../Redux/Slices/AlertSlice';

interface IAlertProps {
  alertOptions: IAlertOptions;
  duration?: number;
  onClose?: () => void;
  children?: JSX.Element[] | JSX.Element;
}

const Alert: React.FC<IAlertProps> = ({ alertOptions, duration, onClose, children }) => {
  setTimeout(() => {
    if (document.getElementById('alert')) document.getElementById('alert')!.classList.add('show');
  }, duration);
  const dispatch = useDispatch();

  if (duration) {
    setTimeout(() => {
      if (onClose) onClose();
    }, duration);
  }

  const handleBackdropClick = () => {
    dispatch(disableAlert());
  };

  return (
    <AlertBackGround onClick={handleBackdropClick}>
      <AlertComponent id="alert" onClick={e => e.stopPropagation()}>
        {alertOptions.iconName && (
          <AlertIcon className="material-icons-outlined">{alertOptions.iconName}</AlertIcon>
        )}
        {alertOptions.title && <AlertTitle>{alertOptions.title || ''}</AlertTitle>}
        {alertOptions.subTitle && <AlertSubTitle>{alertOptions.subTitle || ''}</AlertSubTitle>}
        {alertOptions.message && (
          <AlertMessage dangerouslySetInnerHTML={{ __html: alertOptions.message || '' }} />
        )}
        <>{children}</>
        <>{alertOptions.children}</>
        <AlertButtonsGroup>
          {alertOptions.buttons &&
            alertOptions.buttons.map((button: IButtonOptions) => (
              <DefaultButton
                key={button.text}
                onClick={button.handler ? button.handler : undefined}
                className={`${button.outline && 'outline '}
                      ${button.clear && 'clear '}
                    `}
              >
                {button.text}
              </DefaultButton>
            ))}
        </AlertButtonsGroup>
      </AlertComponent>
    </AlertBackGround>
  );
};

export default Alert;
