import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  client: {
    name: '',
    email: '',
    tel: '',
    cpf: '',
  },
  queueId: '',
  fromDepartment: {
    id: '',
  },
  toDepartment: {
    id: '',
    identifier: '',
  },
};

const transferenceCallData = createSlice({
  name: 'transferCallData',
  initialState,
  reducers: {
    updateTransferenceData: (_, action: PayloadAction<typeof initialState | null>) => {
      if (!action.payload) return initialState;
      return {
        ...action.payload,
      };
    },
  },
});

const { actions, reducer } = transferenceCallData;

export const { updateTransferenceData } = actions;

export default reducer;
