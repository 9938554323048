import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppComponent } from './AppStyle';
import { NewLoading } from './Components/NewLoading/NewLoading';
import { LoadingWrapper } from './Components/VideoWrapper/VideoRoomStyle';
import ClientAppProvider from './Providers/ClientAppProvider';
import store from './Redux';
import Router from './Routes/Router';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <Suspense
      fallback={
        <LoadingWrapper>
          <NewLoading />
        </LoadingWrapper>
      }
    >
      <Provider store={store}>
        <ClientAppProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AppComponent>
              <ToastContainer />
              <Router />
            </AppComponent>
          </QueryClientProvider>
        </ClientAppProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
