import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IAlertOptions } from '../../Components/Alert/AlertController';

const initialState = {
  alert: false,
  alertOptions: {
    title: '',
    subTitle: '',
    message: '',
    className: '',
    style: {},
    buttons: [],
  },
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    enableAlert: (state, action) => ({
      ...state,
      alert: action.payload.alert,
      alertOptions: action.payload.alertOptions,
    }),
    disableAlert: state => ({ ...state, ...initialState }),
  },
});

const { reducer, actions } = alertSlice;

export const { enableAlert, disableAlert } = actions;

export const alertAction =
  (active: boolean, payload: { alert: false; alertOptions: IAlertOptions }) =>
  (dispatch: Dispatch) => {
    if (active) {
      dispatch(enableAlert(payload));
    } else {
      dispatch(disableAlert());
    }
  };

export default reducer;
