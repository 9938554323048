import React from 'react';
import { useTranslation } from 'react-i18next';
import { RatingContent } from '../VideoRating/VideoRatingStyles';

export const DisconnectByDuplicatedIdentity: React.FC = () => {
  const { t: translate } = useTranslation('disconnect');

  return (
    <RatingContent>
      <div className="rating-box">
        <span className="rating-legend">
          {translate('DISCONNECT_BY_DUPLICATED_IDENTITY.MESSAGE')}
        </span>
      </div>
    </RatingContent>
  );
};
