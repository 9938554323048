import styled from 'styled-components';
import { Card } from '../../AppStyle';

export const AlertComponent = styled(Card)`
  width: 30em;
  background: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 3em auto;
  margin-top: -100%;
  &.show {
    margin-top: 3em;
  }
  transition: margin 0.2s;
  @media screen and (max-width: 767px) {
    width: 95vw;
    box-sizing: border-box;
    overflow: auto;
    max-height: calc(100% - 30px);
    box-sizing: border-box;
    &.show {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
`;

export const AlertBackGround = styled.div`
  position: fixed;
  overflow: hidden;
  background: #0000004a;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2999;
`;

export const AlertTitle = styled.h2`
  text-align: center;
  font-size: 1.8em;
  margin: 0.5em;
  font-family: var(--font-family);
  letter-spacing: 0;
  color: #707070;
`;

export const AlertSubTitle = styled.h4`
  white-space: pre-line;
  text-align: center;
  font-size: 1.2em;
  margin: 0.5em;
  font-weight: 600 !important;
  font-family: var(--font-family);
  letter-spacing: 0;
  color: #707070;
`;

export const AlertMessage = styled.p`
  text-align: center;
  font-size: 1em;
  font-weight: 400 !important;
  font-family: var(--font-family);
  letter-spacing: 0;
  color: #707070;
`;

export const AlertButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em;
`;

export const AlertIcon = styled.i`
  font-size: 6em !important;
  width: 100%;
  text-align: center;
  color: var(--dark-blue);
`;
