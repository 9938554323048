import styled from 'styled-components';
import { ILoadingProps } from './Loading';

export const DivLoadingBG = styled.div<ILoadingProps>`
  ${props =>
    props.full &&
    `
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #00000070;
    opacity: 1;
  `}

  ${props => props.backgroundTransparent && `background: transparent;`}
`;

export const DivLoading = styled.div<ILoadingProps>`
  color: official;
  display: inline-block;
  ${props =>
    props.size === 'sm' &&
    `
    width: 24px;
    height: 24px;
  `}
  ${props =>
    props.size === 'md' &&
    `
    width: 36px;
    height: 36px;
  `}
  ${props =>
    props.size === 'lg' &&
    `
    width: 64px;
    height: 64px;
  `}
  position: relative;
  div {
    ${props =>
      props.size === 'sm' &&
      `
      transform-origin: 12px 12px;
    `}
    ${props =>
      props.size === 'md' &&
      `
      transform-origin: 18px 18px;
    `}
    ${props =>
      props.size === 'lg' &&
      `
      transform-origin: 32px 32px;
    `}
    animation: lds-spinner 1.2s linear infinite;
  }
  div:after {
    content: ' ';
    display: block;
    position: absolute;
    ${props =>
      props.size === 'sm' &&
      `
        top: 1px;
        left: 11px;
        width: 2px;
        height: 5px;
    `}
    ${props =>
      props.size === 'md' &&
      `
        top: 2px;
        left: 16px;
        width: 4px;
        height: 8px;
    `}
    ${props =>
      props.size === 'lg' &&
      `
        top: 3px;
        left: 29px;
        width: 5px;
        height: 14px;
    `}
    ${props =>
      props.colorSpinner
        ? `
        background: ${props.colorSpinner};
      `
        : `
        background: black;
    `}
    border-radius: 20%;
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
