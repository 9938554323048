import React from 'react';
import { DivLoading, DivLoadingBG } from './LoadingStyle';

const createDivs = (): any[] => {
  const divs: any[] = [];
  for (let i = 0; i < 12; i++) {
    divs.push(<div key={i} />);
  }
  return divs;
};

export interface ILoadingProps {
  full?: boolean;
  size?: 'sm' | 'md' | 'lg';
  style?: object;
  className?: string;
  colorSpinner?: string;
  backgroundTransparent?: boolean;
}

const Loading: React.FC<ILoadingProps> = ({
  full = true,
  backgroundTransparent = false,
  size = 'lg',
  style,
  className,
  colorSpinner,
}) => (
  <DivLoadingBG
    full={full}
    style={style}
    className={className}
    backgroundTransparent={backgroundTransparent}
  >
    <DivLoading size={size} colorSpinner={colorSpinner}>
      {createDivs()}
    </DivLoading>
  </DivLoadingBG>
);

export default Loading;
