export const Routes = {
  base: '/',
  errorMedia: '/error-medias',
  disconnected: '/desconectado',
  callEnded: '/chamada-finalizada',
  share: (identifier: string) => `/share/${identifier}`,
  call: (token: string) => `/chat/${token}`,
  callGuest: (token: string) => `/chat/${token}/guest`,
  callRating: (token: string, departmentIdentifier: string) =>
    `/avaliacao/${token}/${departmentIdentifier}`,
  waitingRoom: (identifier: string) => `/${identifier}`,
  scheduleRoom: (identifier: string) => `/agendamento/${identifier}`,
  cancelScheduleRoom: (identifier: string) => `/agendamento/cancelar/${identifier}`,
  rescheduleRoom: (identifier: string) => `/agendamento/reagendar/${identifier}`,
} as const;
