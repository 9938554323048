import { isMobile as isMobileDevice } from 'react-device-detect';

const getIsMobile = () => {
  const width = window.innerWidth * ((window as any).visualViewport?.scale || 1);
  const height = window.innerHeight * ((window as any).visualViewport?.scale || 1);
  const isMobileScreenSize = height > width && width <= 520 && height <= 1024;

  const isFrame = window.location !== window.parent.location;
  const isWebview = !!(window as any).originalPostMessage;

  return isFrame || isWebview ? isMobileScreenSize : isMobileDevice;
};

const isMobile = (() => {
  if (navigator.userAgent === 'Android wv' || navigator.userAgent === 'iOS wv') return true;
  return getIsMobile();
})();

const isIframe = (() => {
  if (window.self !== window.top) {
    return true;
  }
  return false;
})();

export { isMobile, isIframe };
