import { Mic, Video } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { DefaultButton, Divisor, SimpleText } from '../../AppStyle';
import { isMobile } from '../../Util/Mobile.util';
import { ContainerCloseButton, RequestMediaPermissionsDiv } from './RequestMediaPermissionsStyle';

enum PermissionMessage {
  BROWSER,
  APP,
}

enum RequestMediaPermissionsView {
  REQUEST_PERMISSIONS,
  PERMISSIONS_DENIED,
}

const RequestMediaPermissions: React.FC<{
  onDisconnect?: (e) => void;
  tryAgain?: () => void;
  onRequest?: () => void;
  permissionDenied?: boolean;
}> = ({ onDisconnect, tryAgain, onRequest, permissionDenied }) => {
  const { t: translate } = useTranslation('media-permission');
  const [permissionMessage, setPermissionMessage] = useState<PermissionMessage>(
    PermissionMessage.BROWSER
  );
  const [view, setView] = useState<RequestMediaPermissionsView>(
    RequestMediaPermissionsView.REQUEST_PERMISSIONS
  );

  const defaultTryAgain = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (permissionDenied) {
      setView(RequestMediaPermissionsView.PERMISSIONS_DENIED);
    } else {
      setView(RequestMediaPermissionsView.REQUEST_PERMISSIONS);
    }
  }, [permissionDenied]);

  return (
    <RequestMediaPermissionsDiv>
      {(() => {
        switch (view) {
          case RequestMediaPermissionsView.REQUEST_PERMISSIONS:
            return (
              <>
                <SimpleText>
                  {translate('REQUEST_MEDIA_PERMISSIONS.REQUEST_PERMISSIONS.TITLE')}
                </SimpleText>
                <div style={{ width: '80%' }}>
                  <ul style={{ margin: 0 }}>
                    <li>
                      <SimpleText style={{ textAlign: 'left', fontSize: '1em' }}>
                        {translate(
                          'REQUEST_MEDIA_PERMISSIONS.REQUEST_PERMISSIONS.ADVICES.ILLUMINATED_ENVIRONMENT.REGULAR'
                        )}{' '}
                        <strong>
                          {translate(
                            'REQUEST_MEDIA_PERMISSIONS.REQUEST_PERMISSIONS.ADVICES.ILLUMINATED_ENVIRONMENT.STRONG'
                          )}
                        </strong>
                      </SimpleText>
                    </li>
                    <li>
                      <SimpleText style={{ textAlign: 'left', fontSize: '1em' }}>
                        {translate(
                          'REQUEST_MEDIA_PERMISSIONS.REQUEST_PERMISSIONS.ADVICES.NETWORK.REGULAR'
                        )}
                        <strong>
                          {translate(
                            'REQUEST_MEDIA_PERMISSIONS.REQUEST_PERMISSIONS.ADVICES.NETWORK.STRONG'
                          )}
                        </strong>
                      </SimpleText>
                    </li>
                    <li>
                      <SimpleText style={{ textAlign: 'left', fontSize: '1em' }}>
                        {translate(
                          'REQUEST_MEDIA_PERMISSIONS.REQUEST_PERMISSIONS.ADVICES.NOT_DRIVE'
                        )}
                      </SimpleText>
                    </li>
                  </ul>
                </div>
                <Divisor style={{ width: '70%', margin: '.5em', opacity: '.4' }} />
                <SimpleText>
                  {translate('REQUEST_MEDIA_PERMISSIONS.REQUEST_PERMISSIONS.HEADLINE')}
                </SimpleText>
                <ContainerCloseButton style={{ width: '100%' }}>
                  <DefaultButton type="button" style={{ marginTop: 0 }} onClick={onRequest}>
                    {translate('REQUEST_MEDIA_PERMISSIONS.REQUEST_PERMISSIONS.BUTTON')}
                  </DefaultButton>
                </ContainerCloseButton>
                <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
                  <Video />
                  <Mic />
                </div>
              </>
            );
          default:
          case RequestMediaPermissionsView.PERMISSIONS_DENIED:
            return (
              <>
                <SimpleText>
                  <strong>{translate('REQUEST_MEDIA_PERMISSIONS.TITLE')}</strong>
                </SimpleText>
                {(() => {
                  if (isMobile) {
                    if (isIOS) {
                      return (
                        <>
                          <SimpleText>
                            {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.IOS.TEXT1')}
                            <span style={{ fontSize: '13px' }}>
                              {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.IOS.TEXT2')}
                            </span>
                            {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.IOS.TEXT3')}
                          </SimpleText>
                          <img
                            src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/ios1.png"
                            alt="Permissão Nuvidio"
                          />
                          <SimpleText>
                            {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.IOS.TEXT4')}
                          </SimpleText>
                          <img
                            src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/ios2.png"
                            alt="Permissão Nuvidio"
                          />
                          <SimpleText>
                            {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.IOS.TEXT5')}
                          </SimpleText>
                          <img
                            src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/ios3.png"
                            alt="Permissão Nuvidio"
                          />
                        </>
                      );
                    }
                    return (() => {
                      switch (permissionMessage) {
                        default:
                        case PermissionMessage.BROWSER:
                          return (
                            <>
                              <SimpleText>
                                {translate(
                                  'REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.BROWSER.TEXT1'
                                )}
                              </SimpleText>
                              <img
                                src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/android1.png"
                                alt="Permissão Nuvidio"
                              />
                              <SimpleText>
                                {translate(
                                  'REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.BROWSER.TEXT2'
                                )}
                              </SimpleText>
                              <img
                                src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/android2.png"
                                alt="Permissão Nuvidio"
                              />
                              <SimpleText>
                                {translate(
                                  'REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.BROWSER.TEXT3'
                                )}
                              </SimpleText>
                              <img
                                src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/android3.png"
                                alt="Permissão Nuvidio"
                              />
                              <DefaultButton
                                type="button"
                                className="clear"
                                onClick={() => setPermissionMessage(PermissionMessage.APP)}
                              >
                                {translate(
                                  'REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.BROWSER.NOT_SOLVED'
                                )}
                              </DefaultButton>
                            </>
                          );
                        case PermissionMessage.APP:
                          return (
                            <>
                              <SimpleText>
                                {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.APP.TEXT1')}
                              </SimpleText>
                              <img
                                src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/android4.jpg"
                                alt="Permissão Nuvidio"
                              />
                              <SimpleText>
                                {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.APP.TEXT2')}
                              </SimpleText>
                              <img
                                src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/android5.jpg"
                                alt="Permissão Nuvidio"
                              />
                              <SimpleText>
                                {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.APP.TEXT3')}
                              </SimpleText>
                              <img
                                src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/android6.jpg"
                                alt="Permissão Nuvidio"
                              />
                              <img
                                src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/android7.jpg"
                                alt="Permissão Nuvidio"
                              />
                              <SimpleText>
                                {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.APP.TEXT4')}
                              </SimpleText>
                              <img
                                src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissions/android8.jpg"
                                alt="Permissão Nuvidio"
                              />
                              <DefaultButton
                                type="button"
                                className="clear"
                                onClick={() => setPermissionMessage(PermissionMessage.BROWSER)}
                              >
                                {translate('REQUEST_MEDIA_PERMISSIONS.MOBILE.ANDROID.APP.BACK')}
                              </DefaultButton>
                            </>
                          );
                      }
                    })();
                  }
                  return (
                    <>
                      <SimpleText>
                        {translate('REQUEST_MEDIA_PERMISSIONS.BROWSER.TEXT1')}
                      </SimpleText>
                      <img
                        src="https://s3-sa-east-1.amazonaws.com/files.nuvidio.com/assets/permissao.png"
                        alt="Permissão Nuvidio"
                      />
                      <SimpleText className="margin-topo">
                        {translate('REQUEST_MEDIA_PERMISSIONS.BROWSER.TEXT2')}
                        <strong>{translate('REQUEST_MEDIA_PERMISSIONS.BROWSER.TEXT3')}</strong>
                      </SimpleText>
                    </>
                  );
                })()}
                <ContainerCloseButton>
                  <DefaultButton type="button" onClick={tryAgain || defaultTryAgain}>
                    {translate('REQUEST_MEDIA_PERMISSIONS.TRY_AGAIN')}
                  </DefaultButton>
                </ContainerCloseButton>
                {onDisconnect && (
                  <ContainerCloseButton>
                    <DefaultButton type="button" className="outline" onClick={onDisconnect}>
                      {translate('REQUEST_MEDIA_PERMISSIONS.ON_DISCONNECT')}
                    </DefaultButton>
                  </ContainerCloseButton>
                )}
              </>
            );
        }
      })()}
    </RequestMediaPermissionsDiv>
  );
};

export default RequestMediaPermissions;
