import React, { useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { VideoOff } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ErrorGetMediasContent } from './ErrorGetMediasStyle';
import { DefaultButton } from '../../AppStyle';

const ErrorGetMedias: React.FC = () => {
  const { t: translate } = useTranslation('waiting-room');
  const { current: query } = useRef(new URLSearchParams(window.location.search));
  const [urlCopied, setUrlCopied] = useState(false);

  const handleCopyUrlToClipBoard = (e: any) => {
    e.preventDefault();
    const url = query.get('url') || '';
    navigator.clipboard.writeText(url);
    setUrlCopied(true);
    setTimeout(() => {
      setUrlCopied(false);
    }, 2000);
  };

  useEffect(() => {
    const primaryColor = query.get('primary-color');
    document.documentElement.style.setProperty('--primary', primaryColor || '#157DD1');
  }, []);

  return (
    <ErrorGetMediasContent>
      <div>
        <VideoOff />
        <p>
          {isIOS
            ? translate('ERROR.GET_MEDIAS.IS_IOS.TEXT1')
            : translate('ERROR.GET_MEDIAS.ANDROID')}
          {isIOS && (
            <>
              <p>
                <b>{translate('ERROR.GET_MEDIAS.IS_IOS.TEXT2')}</b>
              </p>
              <DefaultButton
                type="button"
                onClick={handleCopyUrlToClipBoard}
                style={{ backgroundColor: 'var(--primary)', margin: '10px 0 0 0' }}
              >
                {translate('ERROR.GET_MEDIAS.IS_IOS.COPY_LINK')}
              </DefaultButton>
              {urlCopied && (
                <p style={{ fontSize: '12px', margin: '10px auto 0 auto', width: 'fit-content' }}>
                  <b>{translate('ERROR.GET_MEDIAS.IS_IOS.COPIED_LINK')}</b>
                </p>
              )}
            </>
          )}
        </p>
      </div>
    </ErrorGetMediasContent>
  );
};

export default ErrorGetMedias;
