import React, { lazy } from 'react';
import { connect } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ErrorBoundary } from '../Components';
import Alert from '../Components/Alert/Alert';
import { IAlertOptions } from '../Components/Alert/AlertController';
import { NewLoading } from '../Components/NewLoading/NewLoading';
import { LoadingWrapper } from '../Components/VideoWrapper/VideoRoomStyle';
import { DisconnectByDuplicatedIdentity } from '../Pages/DIsconnect/DisconnectByDuplicatedIdentity';
import ErrorGetMedias from '../Pages/ErrorGetMedias/ErrorGetMedias';
import { Routes } from './Routes';

const Home = lazy(() => import('../Pages/Home/Home'));
const Call = lazy(() => import('../Pages/Call/Call'));
const WaitingRoom = lazy(() => import('../Pages/WaitingRoom/WaitingRoom'));
const Share = lazy(() => import('../Pages/Share/Share'));
const VideoRating = lazy(() => import('../Pages/VideoRating/VideoRating'));
const CallFinished = lazy(() => import('../Pages/CallFinished/CallFinished'));
const ScheduleRoom = lazy(() => import('../Pages/ScheduleRoom/ScheduleRoom'));
const ScheduleCancel = lazy(() => import('../Pages/ScheduleRoom/ScheduleCancel/ScheduleCancel'));

interface RoutesProps {
  loading: boolean;
  alert: boolean;
  alertOptions: IAlertOptions;
}

export const browserRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={Routes.base} element={<Home />} />
      <Route path={Routes.errorMedia} element={<ErrorGetMedias />} />
      <Route path={Routes.disconnected} element={<DisconnectByDuplicatedIdentity />} />
      <Route path={Routes.share(':identifier')} element={<Share />} />
      <Route path={Routes.call(':token')} element={<Call />} />
      <Route path={Routes.callGuest(':token')} element={<Call />} />
      <Route
        path={Routes.callRating(':token', ':departmentIdentifier')}
        element={<VideoRating />}
      />
      <Route path={Routes.callEnded} element={<CallFinished />} />
      <Route path={Routes.waitingRoom(':identifier')} element={<WaitingRoom />} />
      <Route path={Routes.scheduleRoom(':identifier')} element={<ScheduleRoom />} />
      <Route path={Routes.cancelScheduleRoom(':token')} element={<ScheduleCancel />} />
      <Route path={Routes.rescheduleRoom(':token')} element={<ScheduleRoom />} />
    </>
  )
);

const Router: React.FC<RoutesProps> = ({ loading = false, alert = false, alertOptions }) => (
  <>
    {loading && (
      <LoadingWrapper>
        <NewLoading />
      </LoadingWrapper>
    )}
    {alert && <Alert alertOptions={alertOptions} />}
    <ErrorBoundary>
      <RouterProvider router={browserRouter} />
    </ErrorBoundary>
  </>
);

const mapStateToProps = (state: any) => {
  const {
    loading: { loading },
    alert: { alert, alertOptions },
  } = state;

  return {
    loading,
    alert,
    alertOptions,
  };
};

export default connect(mapStateToProps)(Router);
